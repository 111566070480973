import * as React from 'react';
import EntityManager from "@services/EntityManager";
import Exchange from "@models/Exchange";
import {Bar} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import MonthPicker from '@components/common/MonthPicker';
import UserSelect from "@components/modelSelect/UserSelect";
import User from "@models/User";
import {getDaysInMonth} from "date-fns";
import InterventionSlot from "@models/InterventionSlot";

export interface IGodinLibStatProps {
    date: Date
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export interface IGodinLibStatState {
    exchange_data: [];
    intervention_data: [];
    date: Date;
    user: null | User;
}

function transformDataForBarChart(interventions, date) {
    const formattedIntervention = {};
    const labels = [];
    const datasets = [];
    const dayInMonth = getDaysInMonth(date); // Fonction que vous devez déjà avoir pour obtenir le nombre de jours dans le mois.

    // Créer une liste des jours dans le mois
    for (let day = 1; day <= dayInMonth; day++) {
        labels.push(day);
    }

    // Trier les interventions par user_id
    interventions.sort((a, b) => a.user_id - b.user_id);

    // Initialiser un objet pour chaque utilisateur avec tous les jours du mois
    interventions.forEach(intervention => {
        // Utiliser le nom de l'utilisateur comme clé
        const userName = intervention.name;

        if (!formattedIntervention[userName]) {
            formattedIntervention[userName] = new Array(dayInMonth).fill(0);
        }
        // Remplir les jours où il y a des interventions
        formattedIntervention[userName][intervention.day - 1] = intervention.count;
    });

    // Créer un dataset pour chaque utilisateur
    for (let userName in formattedIntervention) {
        datasets.push({
            label: userName, // Utiliser le nom de l'utilisateur ici
            backgroundColor: getRandomColor(), // Vous pouvez personnaliser les couleurs pour chaque utilisateur
            borderColor: getRandomColor(),
            borderWidth: 1,
            data: formattedIntervention[userName],
        });
    }

    return {
        datasets,
        labels,
    };
}


// Fonction pour générer des couleurs aléatoires pour chaque utilisateur (vous pouvez la modifier ou définir des couleurs fixes)
function getRandomColor() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgba(${r},${g},${b},0.4)`;
}

export default class GodinLibStat extends React.PureComponent<IGodinLibStatProps, IGodinLibStatState> {
    constructor(props) {
        super(props);
        this.state = {
            exchange_data: [],
            intervention_data: [],
            date: props.date,
            user: null,
        };

        this.handleMonthChange = this.handleMonthChange.bind(this);
    }

    componentDidMount() {
        this.getExchange(this.state.date.getMonth() + 1, this.state.date.getFullYear(), null);
    }

    async getExchange(month, year, user) {
        let path = "godinlib/stats/" + year + "/" + month;
        let interventions = await EntityManager.getCollection<InterventionSlot>(InterventionSlot, {path: path});
        this.setState({intervention_data: interventions.data});
    }

    handleMonthChange(newDate: Date) {
        this.getExchange(newDate.getMonth() + 1, newDate.getFullYear(), this.state.user);
        this.setState({date: newDate});
    }

    selectUser(e) {
        this.setState({user: e});
        this.getExchange(this.state.date.getMonth() + 1, this.state.date.getFullYear(), e);
    }

    public render() {
        return (
            <div className="app-content" style={{overflow: 'hidden', height: '100vh'}}>
                <MonthPicker initialMonth={this.state.date} onMonthChange={this.handleMonthChange}/>
                {this.state.date &&
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <div style={{flex: '1 0 50%', padding: '10px'}}>
                            <Bar data={transformDataForBarChart(this.state.intervention_data, this.state.date)}/>
                        </div>
                    </div>
                }

            </div>
        );
    }
}
