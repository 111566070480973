import AsyncButton from '@components/button/AsyncButton';
import TemplateWithOverview from '@components/crm/TemplateWithOverview';
import SmartInputText from '@components/input/SmartInputText';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import Contact from '@models/Contact';
import CustomEmail from '@models/CustomEmail';
import Sector from '@models/Sector';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';

export interface ICustomEmailFormProps extends IInjectedFormModelProps<CustomEmail> {
  type: string
}

export interface ICustomEmailFormState {
}

class CustomEmailForm extends React.Component<ICustomEmailFormProps & IFormModelProps<CustomEmail>, ICustomEmailFormState> {
  constructor(props: ICustomEmailFormProps & IFormModelProps<CustomEmail>) {
    super(props);

    this.state = {
    }
    props.model.type = props.type;
  }
  //TODO: Rafraichir la page lors de la modification du mail
  public render() {
    const {model, submit, submitting, id} = this.props;
    model.type = "email"
    console.log(this.props)
    if (!model.contact) {
      model.withContact(
        new Contact({
          firstname: "Clément", lastname: "Blado", email: "clementblado@gmail.com", reference: "56re85", civ: "Mr ", phone: "0565327302", portable: "0682828282", address: {"address": "9 rue jacqueline auriol", "city": "Toulouse", "cp": "31400"}, counter: {"diameter": "40cm", "accessibility": "Accessible"}
        })
      ).withSector(
        new Sector({name: "EDTM"})
      ).withDateRdv(
        new Date()
      )
    }
    
    console.log(model);
    
    return (
      <SidebarLayout
        title={id ? "Modification d'une template" : "Création d'une template"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={submit} >{id ? "Modifier la template" : "Créer la template"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <SmartInputText model={model} name="title" label="Intitulé" containerClassName="mb-3" />
          <TemplateWithOverview model={model}  />
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<ICustomEmailFormProps>(CustomEmailForm, {modelClass: CustomEmail})