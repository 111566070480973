import * as React from 'react';
import ApiService from '@services/ApiService';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colors } from 'src/helper/CoreHelper';


export interface IInterventionStatePageProps {
}

export default class InterventionStatePage extends React.Component<IInterventionStatePageProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      data: null
    }
  }

  globalColors = {
    "complete": "#33d16c66", 
    "planned": "#edc24166", 
    "to_plan": "#ed824166", 
    "unknow": "#bbc5d166"
  }

  excluStatusKey = ["IMP", "INJ", "NE PAS RAPPELER", "REFUS", "MANQ", "EXCLUS"]
  statusKey = {
    "MODULE": "interventions à faire en module",
    "FAIT": "interventions réalisées avec succès",
    "A CHARGER": "interventions restantes à programmer",
    "RDV": "interventions planifiées avec l'abonné",
    "1 APPEL": "1ère relance",
    "A RAPPELER": "Abonné à contacter suite à un avis de passage",
    "2 APPEL": "2ème relance",
    "3 APPEL": "3ème relance",
    "CHARGEE": "Interventions chargées sur nos agents",
    "IMP": "A valider avec le chef de secteur pour les exclure",
    "INJ": "Abonné injoignable (pas de téléphone ni de mail)",
    "REFUS": "Refus de l'intervention par l'abonné",
    "MANQ": "Interventions inexistantes dans le logiciel client mais présentes dans le CRM",
    "EXCLUS": "interventions ne pouvant pas être réalisées par le sous-traitant",
    "NE PAS RAPPELER": " Interventions dont l'abonné a résilié son abonnement - Passage de terrain effectué",
    "IMP VALIDE": "IMP validée par le chef de secteur",
    "ANNULE": "Annulé",
  }

  exclusStatusK = {
    "IMP": "A valider avec EDTM pour les exclure",
    "INJ": "Abonné injoignable (pas de téléphone ni de mail)",
    "REFUS": "Refus de l'intervention par l'abonné",
    "MANQ": "interventions non visibles dans MOVEO",
    "EXCLUS": "interventions ne pouvant pas être réalisées par le sous-traitant",
    "NE PAS RAPPELER": "Contrat résilié par EDTM",
  }


  async componentDidMount() {
    let res: any = await ApiService.get("intervention_states/stat");
    let data: any = {}
    data.excluInterventions = res.data.bad_interventions
    data.globalStatus = res.data.global_status
    data.detailStatus = res.data.details_status
    data.excluStatus = []
    data.globalStatusQuartier = res.data.global_status_quartiers
    data.detailStatusQuartier = res.data.details_status_quartiers
    data.excluStatusQuartier  = {}

    let globalTotal = 0;
    data.globalStatus.forEach(row => {
      globalTotal += row.total
    })
    data.globalStatus.forEach((row, i) => {
      data.globalStatus[i]["percent"] = row.total / globalTotal;
    })

    Object.keys(data.globalStatusQuartier).forEach((d) => {
      let globalTotal = 0;
      data.globalStatusQuartier[d].forEach(row => {
        globalTotal += row.total
      })
      data.globalStatusQuartier[d].forEach((row, i) => {
        data.globalStatusQuartier[d][i]["percent"] = row.total / globalTotal;
      })
    })

    let detailTotal = 0;
    data.detailStatus.forEach(row => {
      detailTotal += row.total
    })
    data.detailStatus.forEach((row, i) => {
      data.detailStatus[i]["percent"] = row.total / detailTotal;
    })

    Object.keys(data.detailStatusQuartier).forEach((d) => {
      let globalTotal = 0;
      data.detailStatusQuartier[d].forEach(row => {
        globalTotal += row.total
      })
      data.detailStatusQuartier[d].forEach((row, i) => {
        data.detailStatusQuartier[d][i]["percent"] = row.total / globalTotal;
      })
    })

    Object.keys(data.detailStatusQuartier).forEach((d) => {
      data.excluStatusQuartier[d] = []
      data.detailStatusQuartier[d].forEach((st, i) => {
        // data.detailStatusQuartier[d][i]["percent"] = st.total / data.detailStatus.find(u => u.ground_status===st.ground_status).total;
        if (this.excluStatusKey.includes(st.ground_status)) {
          data.excluStatusQuartier[d].push(st);
        }
      })
      data.detailStatusQuartier[d] = data.detailStatusQuartier[d].filter(st => !this.excluStatusKey.includes(st.ground_status));
      // data.detailStatusQuartier[d].push({total: exclu, percent: excluPercent, ground_status: "EXCLU"});
    })
    data.detailStatus.forEach((st) => {
      if (this.excluStatusKey.includes(st.ground_status)) {
        data.excluStatus.push(st);
      }
    })
    data.detailStatus = data.detailStatus.filter(st => !this.excluStatusKey.includes(st.ground_status));
    // data.detailStatus.push({total: exclu, percent: excluPercent, ground_status: "EXCLU"});

    let excluTotal = 0;
    data.excluStatus.forEach(row => {
      excluTotal += row.total
    })
    data.excluStatus.forEach((row, i) => {
      data.excluStatus[i]["percent"] = row.total / excluTotal;
    })

    Object.keys(data.excluStatusQuartier).forEach((d) => {
      let globalTotal = 0;
      data.excluStatusQuartier[d].forEach(row => {
        globalTotal += row.total
      })
      data.excluStatusQuartier[d].forEach((row, i) => {
        data.excluStatusQuartier[d][i]["percent"] = row.total / globalTotal;
      })
    })
  
    this.setState({data});
  }

  globalToDoughnut(data) {
    data.sort((a, b) => {
      if(a.status < b.status) { return -1; }
      if(a.status > b.status) { return 1; }
      return 0;
    })
    
    let d = data.map(s => s.total)
    return {
      datasets: [{
        data: d,
        backgroundColor: data.map(s => this.globalColors[s.status]),
        borderColor: data.map(s => this.globalColors[s.status])
      }],
      labels: data.map(s => this.globalStatusToHuman(s.status))
    }
  }

  detailToDoughnut(data) {
    return {
      datasets: [{
        data: data.map(s => s.total),
        backgroundColor: data.map(s => colors[s.ground_status]),
        borderColor: data.map(s => colors[s.ground_status])
      }],
      labels: data.map(s => s.ground_status)
    }
  }

  globalStatusToHuman(status) {
    switch (status) {
      case "to_plan":
        return "A planifier"
      case "planned":
        return "Planifiée"
      case "complete":
        return "Terminée"
      default:
        return "Inconnu"
    }
  }

  renderTable = (keyData, secondKey, callStyle: (r) => any = (row) => {}, callLabel = (r) => r[secondKey]) => {
    return <table className='table'>
      <thead>
        <tr>
          <th>Statut</th>
          <th>Total</th>
          <th>Quartier 2.1</th>
          <th>Quartier 4.1</th>
          <th>Quartier 6.1</th>
        </tr>
      </thead>
      <tbody>
        { this.state.data[keyData].map((row, i) => {
          let style = callStyle(row);
          let q2 = this.state.data[keyData + "Quartier"]["2,1"].find(u => u[secondKey]===row[secondKey])
          let q4 = this.state.data[keyData + "Quartier"]["4,1"].find(u => u[secondKey]===row[secondKey])
          let q6 = this.state.data[keyData + "Quartier"]["6,1"].find(u => u[secondKey]===row[secondKey])
          
          return <tr>
            <td style={style}>
              <b>{callLabel(row)}</b>
            </td>
            <td style={style}>{row.total + " (" + (row.percent * 100).toFixed(0) + "%)"}</td>
            <td style={style}>
              {q2 ? q2.total + " (" + (q2.percent * 100).toFixed(0) + "%)" : 0}
            </td>
            <td style={style}>
              {q4 ? q4.total + " (" + (q4.percent * 100).toFixed(0) + "%)" : 0}
            </td>
            <td style={style}>
              {q6 ? q6.total + " (" + (q6.percent * 100).toFixed(0) + "%)" : 0}
            </td>
          </tr>
        })}
      </tbody>
    </table>
  }

  public render() {
    const {data} = this.state;
    console.log(data);
    let formatter = (value, ctx) => {
      let sum = 0;
      let dataArr = ctx.chart.data.datasets[0].data;
      dataArr.forEach((data: number) => {
          sum += data;
      });
      if ((value*100 / sum) < 4) return null;
      return (value*100 / sum).toFixed(2)+"%";
    }
    
    if (!data) return <></>
    return (
        <div className="d">
          <div className="app-content">
            <h2 className="fw-bold mb-5">Statut des interventions</h2>
            <h4 className="fw-bold my-5">STATUT MOVEO</h4>
            <div className="row">
              <div className='col-md-6 col-12 mb-5'>
                <Doughnut data={this.globalToDoughnut(data.globalStatus)} plugins={[ChartDataLabels]} style={{maxHeight: "450px"}} options={{
                    plugins: {
                      legend: {
                        position: "right",
                      },
                      datalabels: {
                        formatter
                      },
                      tooltip: {
                        callbacks: {
                          label: (context) => {
                            return context.parsed.toString();
                          }
                        }
                      }
                    }
                  }}
                />
              </div>
              <div className='col-md-6 col-12 mb-5'>
              </div>
            </div>
            <div className="row">
              {Object.keys(data.globalStatusQuartier).sort((a, b) => a.localeCompare(b)).map(key => (
                <div className='col-md-4 col-12 mb-3'>
                  <h5 className='mb-3 fw-bold text-secondary text-center'>Quartier {key}</h5>
                  <div>
                    <Doughnut data={this.globalToDoughnut(data.globalStatusQuartier[key])} plugins={[ChartDataLabels]} style={{maxHeight: "450px"}} options={{
                        plugins: {
                          datalabels: {
                            formatter
                          },
                          legend: {
                            display: false
                          },
                          tooltip: {
                            callbacks: {
                              label: (context) => {
                                return context.parsed.toString();
                              }
                            }
                          }
                        }
                      }} />
                  </div>
                </div>
              ))}
            </div>
            {this.renderTable("globalStatus", "status", (row) => ({backgroundColor: this.globalColors[row.status]}), (row) => this.globalStatusToHuman(row.status))}
            <div className='py-5'></div>
            <div className="row my-5">
              <div className='col-md-6 col-12 mb-5'>
              <h4 className="fw-bold">STATUTS EN DETAIL</h4>
                <Doughnut data={this.detailToDoughnut(data.detailStatus)} plugins={[ChartDataLabels]} style={{maxHeight: "450px"}} options={{
                    plugins: {
                      legend: {
                        position: "right",
                      },
                      datalabels: {
                        formatter
                      },
                      tooltip: {
                        callbacks: {
                          label: (context) => {
                            return context.parsed.toString();
                          }
                        }
                      }
                    }
                  }}
                />
              </div>
              <div className='col-md-6 col-12 mb-3'>
              <div className='mb-3 fw-bold mt-2 text-secondary'>Légende en détail</div>
              {Object.keys(this.statusKey).map(status => (
                <div className="">
                  <div className="d-inline-block size-2 me-2 rounded-pill" style={{backgroundColor: colors[status]}}></div>
                  <span className='fw-bold me-2'>{status} :</span>
                  <span>
                    {this.statusKey[status]}
                  </span>
                </div>
              ))}
              </div>
            </div>
            <div className="row">
              {Object.keys(data.detailStatusQuartier).sort((a, b) => a.localeCompare(b)).map(key => (
                <div className='col-md-4 col-12 mb-3'>
                  <h5 className='mb-3 fw-bold text-secondary text-center'>Quartier {key}</h5>
                  <div>
                    <Doughnut data={this.detailToDoughnut(data.detailStatusQuartier[key])} plugins={[ChartDataLabels]} style={{maxHeight: "450px"}} options={{
                      plugins: {
                        legend: {
                          display: false
                        },
                        datalabels: {
                          formatter
                        },
                        tooltip: {
                          callbacks: {
                            label: (context) => {
                              return context.parsed.toString();
                            }
                          }
                        }
                      }
                    }} />
                  </div>
                </div>
              ))}
            </div>
            {this.renderTable("detailStatus", "ground_status", (row) => ({backgroundColor: colors[row.ground_status]}))}
            <div className='py-5'></div>
            <h4 className="fw-bold my-5">EXCLUSIONS EN DETAIL</h4>
            <div className="row">
              <div className='col-md-6 col-12 mb-5'>
                <Doughnut data={this.detailToDoughnut(data.excluStatus)} plugins={[ChartDataLabels]} style={{maxHeight: "450px"}} options={{
                    plugins: {
                      legend: {
                        position: "right",
                      },
                      datalabels: {
                        formatter
                      },
                      tooltip: {
                        callbacks: {
                          label: (context) => {
                            return context.parsed.toString();
                          }
                        }
                      }
                    }
                  }}
                />
              </div>
              <div className='col-md-6 col-12 mb-3'>
                <div className='mb-3 fw-bold text-secondary'>Légende en détail</div>
                  {Object.keys(this.exclusStatusK).map(status => (
                    <div>
                      <div className="">
                        <div className="d-inline-block size-2 me-2 rounded-pill" style={{backgroundColor: colors[status]}}></div>
                        <span className='fw-bold me-2'>{status} :</span>
                        <span>
                          {this.exclusStatusK[status]}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="row">
              {Object.keys(data.excluStatusQuartier).sort((a, b) => a.localeCompare(b)).map(key => (
                <div className='col-md-4 col-12 mb-3'>
                  <h5 className='mb-3 fw-bold text-secondary text-center'>Quartier {key}</h5>
                  <div>
                    <Doughnut data={this.detailToDoughnut(data.excluStatusQuartier[key])} plugins={[ChartDataLabels]} style={{maxHeight: "450px"}} options={{
                        plugins: {
                          legend: {
                            display: false
                          },
                          datalabels: {
                            formatter
                          },
                          tooltip: {
                            callbacks: {
                              label: (context) => {
                                return context.parsed.toString();
                              }
                            }
                          }
                        }
                      }} />
                  </div>
                </div>
              ))}
            </div>
            {this.renderTable("excluStatus", "ground_status", (row) => ({backgroundColor: colors[row.ground_status]}))}
          </div>
        </div>
    );
  }
}
