import { sessionBloc } from '@bloc/SessionBloc';
import AdminModelsList from '@components/common/AdminModelsList';
import TextIcon from '@components/common/TextIcon';
import GodinLibGrid from '@components/grid/GodinLibGrid';
import SectorGrid from '@components/grid/SectorGrid';
import MainLayout from '@components/layout/MainLayout';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import Sector from '@models/Sector';
import User from '@models/User';
import DOMService from '@services/DOMService';
import { filter } from 'lodash';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

export interface IGodinCRMPageProps {
}

export default class GodinLibCRMPage extends React.Component<IGodinCRMPageProps> {

  goToStat = () => {
    sessionBloc.push("/crm/godinlib/stats")
  }

  public render() {
    return (
      <MainLayout title="Godin lib">
        <div className="app-content">
          <div className="row-flex mb-3">
            <i onClick={() => sessionBloc.pop()} className="fas fa-chevron-left pe-4 pointer"></i>
            <h2 className='mb-0 text-capitalize'>
              <span className='me-3 fw-bold'></span>
            </h2>
            <div className="ms-auto"></div>
            <button className="btn btn-primary ms-3" onClick={this.goToStat}>Voir les statistiques.
            </button>
          </div>

          <AdminModelsList
            gridClass={GodinLibGrid}
            options={{ loadOnReady: true, paginate: true, modelClass: User, params: { scope: "onlyGodinLib", _with: ["sector"] } }}
          />
        </div>
      </MainLayout>
    );
  }
}