import FullCalendar, { CustomContentGenerator, DayHeaderContentArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import InterventionSlot from '@models/InterventionSlot';
import Model from "@models/Model";
import EntityManager from '@services/EntityManager';
import listPlugin from '@fullcalendar/list';
import * as React from 'react';
import CountDisplayer from './CountDisplayer';
import SmartInputText from '@components/input/SmartInputText';
import SmartSwitch from '@components/input/SmartSwitch';
import dayjs from 'dayjs';

export interface IContactSlotCalendarProps {
  parentModel: Model
  contentHeight?
  params?
  onSelect?: (start: Date, end: Date) => void
  add?: InterventionSlot,
  onSlotClick?
  eventContent?
  eventsInBackground?
  canEdit?
  list?
  with?
  hideAmountByDay?
  withCount?
}

export default class ContactSlotCalendar extends React.Component<IContactSlotCalendarProps, {interventionSlots: InterventionSlot[], address: string, inFuture: boolean}> {

  constructor(props) {
    super(props);
    this.state = {
      interventionSlots: null,
      address: "",
      inFuture: true
    }
  }

  ref = React.createRef<FullCalendar>();
  refDate

  componentDidMount() {
    this.loadInterventionSlots();
  }

  async loadInterventionSlots() {
    let params = {_with: this.props.with || ["contact"]}
    if (this.state.inFuture) params["scope"] = "inFuture";
    if (this.props.params) params = {...params, ...this.props.params}
    let response = await EntityManager.all<InterventionSlot>(InterventionSlot, {parentModel: this.props.parentModel, params});
    this.setState({interventionSlots: response.models});
    
  }

  onSlotClick = (slot: InterventionSlot) => {
    return null
    // DOMService.openSidebar(SidebarRoute.InterventionSlotForm, {id: slot.id, onSubmit: () => {
    //   DOMService.closeSidebar();
    //   this.loadInterventionSlots();
    // }});
  }

  componentDidUpdate(prevProps: Readonly<IContactSlotCalendarProps>, prevState: Readonly<{ interventionSlots: InterventionSlot[]; }>, snapshot?: any): void {
    if (prevProps.parentModel !== this.props.parentModel) this.loadInterventionSlots();
  }

  getEvents = () => {
    let slots = []
    if (this.props.add) slots.push({
      title: "RDV",
      start: this.props.add.startAt,
      end: this.props.add.endAt,
      color: "#5ab977",
      backgroundColor: "#5ab977",
    });
    if (this.state.interventionSlots) slots = slots.concat(this.state.interventionSlots.map((slot: InterventionSlot) => ({
      title: "",
      start: slot.startAt,
      slot: slot,
      display: this.props.eventsInBackground ? "background" : null,
      end: slot.endAt,
      color: slot.getColor(),
      backgroundColor: slot.getColor(),
    })))


    return slots;
  }

  eventUpdate = async (e) => {
    if (e.event.title==="RDV") return;
    let slot: InterventionSlot = e.event.extendedProps.slot;
    
    slot.startAt = e.event.start;
    slot.endAt = e.event.end;
    await EntityManager.update(slot, {only: ["startAt", "endAt"]});
  }

  select = async (e) => {
    this.props.onSelect(e.start, e.end);
  }

  onFilterAddress(address) {
    this.setState({address});
  }

  toggleInFuture() {
    this.setState({inFuture: !this.state.inFuture}, () => this.loadInterventionSlots());
  }

  public render() {
    const {contentHeight, onSelect, list, eventContent, withCount, hideAmountByDay} = this.props;
    const {address, inFuture} = this.state;
    let events = this.getEvents();
    let filteredEvents = events;
    if (address) {
      filteredEvents = filteredEvents.filter((e) => e.slot.matchWithAddress(address));
    }
    let headersDay: CustomContentGenerator<DayHeaderContentArg>
    if (!hideAmountByDay) headersDay = (_) => {
      return _ != null ? <div className='row-flex'>
      <div>{_.text}</div>
      <div style={{fontWeight: "400"}} className='ms-4'>{filteredEvents.filter((ev) => {
        return ev.slot != null && ev.slot.startAt?.getDate() == _?.date?.getDate();
      }).length} rdv</div>
      <div className='ms-auto'>{dayjs(_.date).format("D MMMM YYYY")}</div>
    </div> : null
    }
    return (
      <>
      <SmartInputText containerClassName='mb-3' placeholder='Rechercher une adresse' onChange={(e) => this.onFilterAddress(e)} value={address} />
      {(this.ref.current) && <div className="d-flex mb-4">
        {withCount && <CountDisplayer total={events.length} calendar={this.ref.current} />}
        <SmartSwitch id={"inFutureSw"} containerClassName='ms-auto' value={inFuture} onChange={(e) => this.toggleInFuture()} label={"Uniquement à venir"}/>
      </div>}
      <FullCalendar
        ref={this.ref}
        plugins={ [listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin] }
        initialView='listWeek'
        headerToolbar={ {
          left: "title",
          center: !list ? "timeGridDay,timeGridWeek,listWeek" : null,
          end: "prev,next",
        } }
        buttonText={{
          today: "Aujourd'hui",
          timeGridDay: "Par jour",
          timeGridWeek: "Par semaine",
          listWeek: "Liste"
        }}
        // schedulerLicenseKey="0344037874-fcs-1637598111"
        contentHeight={contentHeight || 'auto'}
        allDaySlot={false}
        dayHeaderContent={headersDay}
        eventContent={eventContent}
        locale={"fr"}
        hiddenDays={[0, 6]}
        slotDuration={'00:30:00'}
        slotMinTime="08:00:00"
        eventClassNames={"pointer"}
        // selectOverlap={false}
        slotMaxTime="18:00:00"
        displayEventTime={list}
        eventClick={this.props.onSlotClick ? (e) => this.props.onSlotClick(e.event._def.extendedProps.slot) : (e) => this.onSlotClick(e.event._def.extendedProps.slot)}
        select={onSelect ? this.select : null}
        editable={this.props.canEdit}
        eventChange={this.eventUpdate}
        eventResize={this.eventUpdate}
        selectable={true}
        events={filteredEvents}
      
      />
      </>
    );
  }
}
