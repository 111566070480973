import { sessionBloc } from '@bloc/SessionBloc';
import InterlocutorItem from '@components/common/InterlocutorItem';
import SearchInput from '@components/input/SearchInput';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Customer from '@models/Customer';
import Interlocutor from '@models/Interlocutor';
import DOMService from '@services/DOMService';
import * as React from 'react';

export interface ICustomerShowPageProps extends IInjectedShowModelProps<Customer> {
  id
}

export interface ICustomerShowPageState {
  stat: any
  sectorInterlocutors: Interlocutor[]
  sectors: any[]
  search: string
}

class CustomerShowPage extends React.Component<ICustomerShowPageProps, ICustomerShowPageState> {
  constructor(props: ICustomerShowPageProps) {
    super(props);

    this.state = {
      stat: null,
      sectorInterlocutors: this.props.model.interlocutors,
      sectors: this.props.model.sectors,
      search: ""
    }
  }

  goToInterlocutorForm() {
    DOMService.openSidebar(SidebarRoute.InterlocutorForm, {
      newModel: new Interlocutor({
        "contactableId": this.props.id,
        "contactableType": "App\\Models\\Customer",
      }),
      onSubmit: () => this.load()
    })
  }

  public goToSectorInterlocutorForm(sectorId) {
    DOMService.openSidebar(SidebarRoute.InterlocutorForm, {newModel: new Interlocutor({
      "contactableId": sectorId,
      "contactableType": "App\\Models\\Sector",
    }), onSubmit: () => this.load()} )
  }

  onSearch = () => {
    this.setState({
      
    })
  }

  onSearchChange = (search) => {
    let sectorInterlocutors = this.props.model.interlocutors.filter(e => e.fullname?.match(new RegExp(search,"gi")));
    let sectors = this.props.model.sectors.map(s => ({
      interlocutors: s.interlocutors.filter(e => e.fullname?.match(new RegExp(search,"gi"))),
      id: s.id,
      name: s.name
    }))
    this.setState({
      search, sectorInterlocutors, sectors
    })
  }

  async load() {
    DOMService.closeSidebar();
    await this.props.load();
  }

  onEdit(i) {
    DOMService.openSidebar(SidebarRoute.InterlocutorForm, {id: i.id, onSubmit: () => this.load()})
  }

  public render() {
    const { model } = this.props;
    const { search, sectorInterlocutors, sectors } = this.state;
    console.log(this.state);
    
    // let interlocutors = groupBy(model.interlocutors.filter((e) => e.fullname), (e) => e.fullname ? e.fullname[0] : "");
    if (!sectorInterlocutors) return <></>
    return (
      <div className="home">
        <div className="px-0 pt-3 px-md-5">
          <div className="app-content">
            <div className="row-flex mb-3">
              <i onClick={() => sessionBloc.pop()} className="fas fa-chevron-left pe-4 pointer"></i>
              <h2 className='mb-0'>Interlocuteurs de <b>{model.name}</b> pour tout les secteurs</h2>
              <div className="ms-auto">
                <button onClick={() => this.goToInterlocutorForm()} className="btn btn-primary">
                  Ajouter un interlocuteur
                </button>
              </div>
            </div>
            <div>
              <div className="row-flex mb-3">
                <div className="card-title mb-0">
                  <SearchInput onChanged={this.onSearchChange} onSearch={this.onSearch} placeholder='Rechercher' value={search}/>
                </div>
              </div>
              <div className="row">
                {sectorInterlocutors.map((i) => (
                  <div key={i.id} className='col-md-3 mb-3'>
                    <InterlocutorItem interlocutor={i} onGoEdit={() => this.onEdit(i)} />
                  </div>
                ))}
              </div>
            </div>
            <div className='pt-7'>
              <div className="card-title">Interlocuteurs par secteurs</div>
              {sectors.map((s) => (
                <div>
                  <div className="row-flex mb-3">
                    <h5 className=" capitalize mb-0">{s.name}</h5>
                    <div className="ms-3">
                      <button onClick={() => this.goToSectorInterlocutorForm(s.id)} className="btn btn-primary">
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    {s.interlocutors.map((i) => (
                      <div key={i.id} className='col-md-3'>
                        <InterlocutorItem interlocutor={i} onGoEdit={() => this.onEdit(i)} />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default showModel(CustomerShowPage, {modelClass: Customer}) as any
