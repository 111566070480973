import { sessionBloc } from "@bloc/SessionBloc";
import SmartInputText from "@components/input/SmartInputText";
import SmartSelect from "@components/input/SmartSelect";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import ChangeQuartierModal from "@components/modal/ChangeQuartierModal";
import ChangeStatusModal from "@components/modal/ChangeStatusModal";
import Address from "@models/Address";
import Contact from "@models/Contact";
import Contract from "@models/Contract";
import Counter from "@models/Counter";
import DOMService from "@services/DOMService";
import OptionService from "@services/OptionService";
import { debounce } from "lodash";
import { convertHexToRGBA } from "src/helper/ColorHelper";
import ModelsTable from "../common/ModelsTable";
import PaginationComponent from "../common/PaginationComponent";
import PerPageSelect from "../common/PerPageSelect";
import ModelGrid from './ModelGrid';
import dayjs from "dayjs";

export default class ContactGrid extends ModelGrid<Contact> {

  public formRoute = SidebarRoute.ContactForm

  public newOptions = () => ({
    onSubmit: this.submit,
    newModel: new Contact({contract: new Contract({}), counter: new Counter({connectionAddress: new Address({})})}),
    parentModel: this.props.manager.props.options.parentModel
  })

  static gridName = "contact"

  title() {
    return "Contacts"
  }

  onSpecificSearch() {
    this.props.manager.onFilter("address", this.props.manager.state.byAddress);
  }

  mergeSavedFilter(data) {
    let f = localStorage.getItem("contactGridFilters");
    if (!f) {
      localStorage.setItem("contactGridFilters", JSON.stringify(f));
    } else {
      let state = JSON.parse(f)
      localStorage.setItem("contactGridFilters", JSON.stringify({...state, ...data}));
    }
  }

  clearAddress() {
    this.props.manager.setState({byAddress: null}, () => this.mergeSavedFilter(this.props.manager.state));
    this.props.manager.onFilter("address", null);
  }

  filter(value, key) {
    const { manager } = this.props;
    let filter = manager.state.filter;
    filter[key] = value;
    manager.setState({filter, page: 1});
    this.mergeSavedFilter(filter)
    this.reload(manager.loadModels);
  }

  toggleStatus(status) {
    const { manager, filter } = this.props;
    let current = filter.real_status_ids || [];
    let newState;
    if (current.includes(status.id)) newState = current.filter(id => id !== status.id);
    else newState = [...current, status.id];
    this.mergeSavedFilter({real_status_ids: newState});
    manager.mergeFilter({real_status_ids: newState})
  }

  reload = debounce((c) => {
    c()
  }, 1000);

  typeOptions = [
    {label: "Tous", id: null},
    {label: "Module", id: "onlyModule"},
    {label: "Compteur", id: "onlyCounter"},
  ]

  firstBar() {
    const { manager, filter } = this.props;


    let quartiersOption = [
      {label: "Tous", id: null},
    ]
    if (OptionService.quartiers) quartiersOption = quartiersOption.concat(OptionService.quartiers[this.props.manager.props.options.parentModel.id]?.map((q) => ({label: q, id: q})))
    
    return <div className="">
      <div className="row-between mb-3">
        {OptionService.get("interventions_status").map((status) => <div className="">
          <div key={status.id + "opt"} onClick={() => this.toggleStatus(status)} className="btn btn-sm text-nowrap rounded-2" style={{
            color: filter.real_status_ids?.includes(status.id) ? "white" : convertHexToRGBA(status?.color, 60),
            backgroundColor: filter.real_status_ids?.includes(status.id) ? status?.color : convertHexToRGBA(status?.color, 10)
          }}>{status?.label}</div>
        </div>)}
        <div onClick={() => {
          manager.mergeFilter({real_status_ids: []})
          this.mergeSavedFilter({real_status_ids: []});
        }} className="btn btn-dark btn-sm text-nowrap rounded-2">Tout</div>
      </div>
      <div className="row">
        {/* <div className="col-md col-12 mb-3">
          <SmartOptionSelect placeholder="Par statut" optionName="interventions_status" value={ filter.realStatus } onChange={ (e) => this.filter(e.id, "realStatusId") } />
        </div> */}
        <div className="col-md col-12 mb-3">
          <SmartInputText value={ filter.search } innerRef={ manager.inputRef } onChange={ (e) => this.filter(e, "search") } placeholder={"Par abonné"} />
        </div>
        <div className="col-md col-12 mb-3">
          <SmartInputText className="" value={ filter.address } onChange={ (e) => this.filter(e, "address") } placeholder={"Par adresse"} />
        </div>
        <div className="col-md col-12 mb-3">
          <SmartInputText className="" value={ filter.city } onChange={ (e) => this.filter(e, "city") } placeholder={"Par commune"} />
        </div>
        <div className="col-md col-12 mb-3">
          <SmartInputText className="" value={ filter.streetNumber } onChange={ (e) => this.filter(e, "streetNumber") } placeholder={"Par numéro de rue"} />
        </div>
        <div className="col-md col-12 mb-3">
          <SmartSelect options={this.typeOptions} value={ filter.scope ? this.typeOptions.find(o => o.id===filter.scope) : null} onChange={ (e) => {
            manager.mergeFilter({scope: e.id});
            this.mergeSavedFilter({scope: e.id});
          } }  placeholder={"Type"} />
        </div>
      </div>
      <div className="row">
        <div className="col-md col-12 mb-3">
          <SmartInputText value={ filter.refPicru } onChange={ (e) => this.filter(e, "refPicru") }  placeholder={"Ref picru"} />
        </div>
        <div className="col-md col-12 mb-3">
          <SmartInputText value={ filter.counterNumber } onChange={ (e) => this.filter(e, "counterNumber") }  placeholder={"Numéro de compteur"} />
        </div>
        <div className="col-md col-12 mb-3">
          <SmartInputText value={ filter.diameter } onChange={ (e) => this.filter(e, "diameter") }  placeholder={"Diamètre"} />
        </div>
        <div className="col-md col-12 mb-3">
          <SmartSelect options={quartiersOption} value={ filter.quartier ? {label: filter.quartier, value: filter.quartier} : null} onChange={ (e) => {
            manager.mergeFilter({quartier: e.id});
            this.mergeSavedFilter({quartier: e.id});
          } }  placeholder={"Par quartier"} />
        </div>
        <div className="col-md col-12 mb-3">
          <SmartInputText value={ filter.phoneNumber } onChange={ (e) => this.filter(e, "phoneNumber") }  placeholder={"Téléphone"} />
        </div>
      </div>
    </div>
  }
  // specificFilters = () => <div className="row-flex">
  //   <SearchInput className="me-3" value={ this.byPhone } innerRef={ this.byPhoneRef } onChanged={ () => this.onSpecificSearch("byPhone") } onSearch={ () => this.onSpecificSearch("byPhone") } placeholder={"Par numéro"} />
  // </div>

  gridConfig: any = {
    headers: ["Contact", "Adresse", "Instruction", "Quartier", "Compteur", "Picru", "Statut"],
    body: [
      (contact: Contact) => <div className="row-flex">
        <b>{contact.getFullName()}</b>
      </div>,
      (contact: Contact) => <div>
        <div>{contact.counter?.connectionAddress?.firstLine()}</div>
        <div>{contact.counter?.connectionAddress?.cpAndCity()}</div>
      </div>,
      (contact: Contact) => <div>
        {contact.exchanges.length > 0 && <div className="row-flex mb-3">
          {contact.exchanges.reverse().map((e) => <div style={{backgroundColor: e.type?.color}} aria-label={dayjs(e.createdAt).format("D MMMM YYYY") + (e.comment ? (" - " + e.comment): "") } data-balloon-break data-balloon-length="large" data-balloon-pos="down" className='width-4 height-4 row-center text-white rounded-pill me-2'>
            <i className={'text-xs fa-solid ' + e.getIcon()}></i>
          </div>)}
        </div>}
        {contact.interventions.map((i) => <div>
          <div>{i.instruction}</div>
        </div>)}
      </div>,
      (contact: Contact) => <div>{contact.quartier}</div>,
      (contact: Contact) => <div>
        <div className="fw-bold">N°{contact.counter?.reference}</div>
        {contact.counter?.diameter && <div>Diamètre {contact.counter?.diameter}</div>}
      </div>,
      (contact: Contact) => <div>
        {contact.interventions.length > 0 && <>
        {contact.interventions.map(i => (
          <div>
            <div>{i.ref}</div>
            <div>Motif {i.motifCode}</div>
          </div>
        ))}
          </>
        }
      </div>,
      (contact: Contact) => <div onClick={(e) => {
        e.stopPropagation();
        DOMService.openSidebar(SidebarRoute.StatusDialog, {contact, onChange: () => this.props.manager.loadModels()})
      }} className="btn" style={{backgroundColor: contact.realStatus?.color, color: "white" }}>{contact.realStatus?.label}</div>,
    ]
  }

  /*async sendEmailToSelectedContacts() {
    const { selectedContacts } = this.state;
    const contactDetailsPromises = selectedContacts.map(contact =>
        ApiService.get(`contacts/${contact.id}/details/receiving-emails`)
    );

    try {
        const contactDetailsResponses = await Promise.all(contactDetailsPromises);
        const allContactDetails = contactDetailsResponses.flatMap(response => response.data);

        const contactEmails = allContactDetails.map(contactDetail => contactDetail.email);

        DOMService.openSidebar(SidebarRoute.SendPage, {
            type: "email",
            to: contactEmails.join(', ')
        });
    } catch (error) {
        console.error("Error fetching contact details: ", error);
    }
}*/

  tableArea() {
    const { models, lastPage, page, perPage, selected, manager, total, filter } = this.props;
    return <div className="">
      <div className="row-flex">
        <PaginationComponent lastPage={lastPage} currentPage={page} onPageChange={manager.onPageChange} />
        <div className="ms-4">
          <PerPageSelect value={perPage} onChange={this.onPerPageChange} />
        </div>
        <div className="ms-4">
          {filter.byInter ?
          <div onClick={() => manager.removeFilters(["byInter"])} className="btn btn-primary">Ordonner par date d'intervention</div> :
          <div onClick={() => manager.mergeFilter({"byInter": true})} className="btn btn-outline-primary">Ordonner par date d'intervention</div>
          }
        </div>
        <div className="ms-auto">
            {total || 0} résultat(s)
        </div>
      </div>
      <ModelsTable
        config={this.gridConfig}
        models={models}
        onRowClick={this.onRowClick}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        selected={selected}
        onSelectedChange={manager.onSelectedChange}
        rowActions={this.rowActions}
        multipleActions={this.multipleActionsRender}
      />
      {models && <div className="d-flex justify-content-between fadeIn">
        <div className="row-flex">
          <PaginationComponent lastPage={lastPage} currentPage={page} onPageChange={manager.onPageChange} />
          <div className="ms-4">
            <PerPageSelect value={perPage} onChange={this.onPerPageChange} />
          </div>
        </div>
        {this.specificFooter()}
      </div>}
    </div>
  }

  onRowClick = (model: Contact) => {
    sessionBloc.push("/crm/contacts/" + model.id, {ids: this.models().map(m => m.id), from: "/crm/sectors/" + model.sectorId});
    // DOMService.onCloseAdminSidebar(() => this.props.manager.loadModels());
    // DOMService.openSidebar(SidebarRoute.FullContactShow, {id: model.id, ids: this.models().map(m => m.id)})
  }

  multipleActions = [
    {
      action: () => DOMService.modal(<ChangeQuartierModal sectorId={this.props.manager.props.options.parentModel.id} selected={this.props.selected} onChange={() => {
        this.props.manager.loadModels();
        this.props.manager.onSelectedChange([])
      }} />),
      label: "Assigner à un quartier"
    },
    {
      action: () => DOMService.modal(<ChangeStatusModal selected={this.props.selected} onChange={() => {
        this.props.manager.loadModels()
      }} />),
      label: "Assigner à un statut"
    },
    {
      action: () => DOMService.openSidebar(SidebarRoute.SendToMultiplePage, {type: "email", contacts: this.props.selected}),
      label: "Envoyer un email aux contact sélectionnées"
    }
  ];

  onDelete = null
  onEdit = null
  onClickNew = null
}