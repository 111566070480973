import Yard from '@models/Yard';
import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { sessionBloc } from '@bloc/SessionBloc';
import classNames from 'classnames';
import TextIcon from '@components/common/TextIcon';
import dayjs from "dayjs";

export interface IYardCardProps {
  yard: any
  className?: string
}

export default class YardCard extends React.Component<IYardCardProps> {

  dayLeftData() {
    if (this.complete()) return {label: "Terminé", className: "success"}
    if (this.props.yard.day_left === 0) return {label: "Deadline dépassé", className: "danger"}
    return {label: `${this.props.yard.day_left} jour(s) restant(s)`, className: "primary"}
  }

  complete() {
    return this.props.yard.progress >= 100;
  }

  smallPeriodStr() {
    return `${dayjs(this.props.yard.start_at).format("DD/MM")} au ${dayjs(this.props.yard.end_at).format("DD/MM")}`;
  }

  getStateClass() {
    if (this.props.yard.closed) return "bg-info";
    return this.props.yard.state === "ok" ? "bg-success" : "bg-danger"
  }

  getStateText() {
    if (this.props.yard.closed) return "Cloturé";
    return this.props.yard.state === "ok" ? "OK" : "Retard";
  }

  regularIcon(compo) {
    console.log(compo)
    return `far ${compo.icon}`;
  }

  progress(compo) {
    return  compo.done.total / compo.quantity * 100;
  }

  progressText(compo) {
    return this.progress(compo).toFixed(0) + " %";
  }

  public render() {
    const {yard, className} = this.props
    let data = this.dayLeftData();

    return (
      <div className={classNames({"yard-card card mb-3": true, [className]: !!className})} onClick={() => sessionBloc.push("/management/yards/" + yard.id)}>
        <div>
          <div className="d-flex justify-content-between mb-3">
            <div>
              <div className="fw-bold text-capitalize">{yard.name}</div>
              <div className="text-s text-secondary">{yard.city}</div>
              <div className="mt-2">
                {this.smallPeriodStr()}
              </div>
            </div>
            <div>
              <div className={"ms-3 badge " + (this.getStateClass())}>{this.getStateText()}</div>
            </div>
          </div>
          <div className="row-between">
            {yard.yard_compositions.map((compo) => (
              <div key={compo.id} className="col-center position-relative">
                <div className="">
                  {console.log(compo)}
                  <i className={this.regularIcon(compo.prestation_type)}></i>
                </div>
                <div className="text-l">
                  {compo.done.total}
                </div>
                <div className="mx-1 position-absolute start-100 text-nowrap text-primary text-xs top-50">
                  {this.progressText(compo)}
                </div>
              </div>
            ))}
            {yard.yard_compositions.length < 4 && <div></div>}
            {yard.yard_compositions.length < 3 && <div></div>}
            {yard.yard_compositions.length < 2 && <div></div>}
          </div>
        </div>
        <div className="mt-auto">
          <div className="row-between py-3">
            <ProgressBar className="flex-1 me-2" now={yard.progress * 100} />
            <div className="text-s">{() => {
              let value = yard.progress * 100;
              if (value > 100) value = 100;
              return value.toFixed(0) + " %"
            }}</div>
          </div>
          <div className="row-between">
            <div title={yard.users.map(u => ([u.firstname, u.lastname].filter(s => s)).join(" ")).join(", ")}>
              <TextIcon leftIcon="fas fa-users">
                <div>{yard.users.length}</div>
              </TextIcon>
            </div>
            <div className={`bg-light-${data.className} rounded px-2 py-1 text-s text-${data.className}`}>{data.label}</div>
          </div>
        </div>
      </div>
    );
  }
}
